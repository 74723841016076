import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import WidgetContentContainer from "../WidgetContent/WidgetContentContainer";
import getWidgets from "./actions";
import fallbackVideo from "../../helpers/fallbackImages/fallbackVideo.mp4";

import { mediaServerWS } from "../../helpers/apiConfig";

import webSocketEvents from "../../webSocket/actions";
import axios from "axios";

const PlayerScreenContainer = () => {
  const widgets = useSelector((state) => state.widgets);
  const dispatch = useDispatch();
  const [image, setImage] = useState("");

  const ws = new WebSocket(mediaServerWS());

  useEffect(() => {
    dispatch(getWidgets());
    let urlString = window.location.href;
    let url = new URL(urlString);
    let mediaServer = url.searchParams.get("mediaServerUrl");
    let newUrl = mediaServer
      ? mediaServer + "/wdt"
      : "https://ms.local.cms.wink.ro/api/wdt";

    setInterval(() => {
      axios.get(newUrl);
    }, 15000);

    // axios
    // 	.get("http://10.0.32.160:81/uploads/client/logo.png")
    // 	.then((res) => {
    // 		if (res.status === 200) {
    // 			setImage({
    // 				type: "photo",
    // 				src: "http://10.0.32.160:81/uploads/client/logo.png",
    // 			});
    // 		}
    // 	})
    // 	.catch((error) => {
    // 		setImage({
    // 			type: "video",
    // 			src: fallbackVideo,
    // 		});
    // 	});
  }, []);

  useEffect(() => {
    if (widgets.playerID) {
      ws.onopen = () => {
        const message = {
          action: "identify",
          playerID: widgets.playerID,
        };
        ws.send(JSON.stringify(message));
      };
    }
  }, [widgets.playerID]);

  ws.onmessage = (event) => {
    let message = JSON.parse(event.data);
    if (message.playNow) {
      dispatch(webSocketEvents(message.playNow));
    }
  };

  const loadingStyle = {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <>
      {widgets.loading && (
        // <div style={loadingStyle}>
        // 	{image.type === "photo" ? (
        // 		<img src={image.src}></img>
        // 	) : (
        // 		<video autoPlay muted loop>
        // 			<source src={image.src} />
        // 		</video>
        // 	)}
        // </div>
        <video autoPlay muted loop>
          <source src={fallbackVideo} />
        </video>
      )}
      {!widgets.loading &&
        widgets.widgets.map((data) => {
          let style = {
            position: "absolute",
            top: data.top,
            left: data.left,
            right: data.right,
            bottom: data.bottom,
            width: data.width,
            height: data.height,
            display: "flex",
            justifyContent: "center",
            zIndex: data["z-index"],
          };
          return (
            <WidgetContentContainer
              widgetID={data.widgetID}
              style={style}
              key={data.widgetID}
            />
          );
        })}
    </>
  );
};

export default PlayerScreenContainer;
